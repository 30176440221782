<template>
  <div>
    <div class="tw-flex tw-flex-wrap tw-justify-between tw-mb-4 tw-mx-2 tw-gap-6">
      <div>
        <button
          type="button"
          title="Checklist laden"
          class="btn btn-default btn-xs tw-mr-1"
          @click="showLoadChecklistModal"
        >
          <i class="fa fa-download" /> Checklist laden
        </button>
        <button
          type="button"
          title="Openstaande items mailen naar makelaar en sales support"
          class="btn btn-default btn-xs tw-mr-1"
          @click="sendChecklistNotification"
        >
          <i class="fa fa-send" /> Openstaande items mailen
        </button>
      </div>
      <div class="tw-flex tw-flex-row tw-flex-wrap tw-gap-4">
        <FormulateInput
          v-model="showOnlyAgent"
          type="toggle"
          name="only_agent"
          label="Alleen makelaar"
          outer-class="tw-m-0"
        />
        <FormulateInput
          v-model="showOnlySalesSupport"
          type="toggle"
          name="only_sales_support"
          label="Alleen sales support"
          outer-class="tw-m-0"
        />
        <FormulateInput
          v-model="includeNvt"
          type="toggle"
          name="include_nvt"
          label="Inclusief n.v.t."
          outer-class="tw-m-0"
        />
        <FormulateInput
          v-model="expandedTodos"
          type="toggle"
          name="expanded_todos"
          label="Toon details"
          outer-class="tw-m-0"
        />
      </div>
    </div>

    <div v-if="categories.length" class="tw-m-2 tw-hidden md:tw-grid tw-grid-cols-3 tw-gap-2">
      <h3
        v-for="(title, column) in CHECKLIST_COLUMN_TITLES"
        :key="column"
        class="tw-m-0"
      >
        {{ title }}
      </h3>
    </div>

    <ChecklistKanban
      :entity="entity"
      :show-na="includeNvt"
      :expanded="expandedTodos"
      :show-only-agent="showOnlyAgent"
      :show-only-sales-support="showOnlySalesSupport"
    />

    <BaseModal ref="loadChecklists" title="Checklist inladen" max-width="tw-max-w-sm">
      <FormulateForm
        #default="{ isLoading }"
        name="loadChecklists"
        invalid-message="Gelieve de verplichte velden correct in te vullen."
        @submit="loadTemplate"
      >
        <FormulateInput
          type="select"
          name="template"
          label="Kies een checklist om in te laden"
          validation="required"
          :options="checklistTemplates"
        />

        <FormulateErrors class="tw-text-right" />

        <div class="tw-flex tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            outer-class="tw-mt-2"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Inladen
          </FormulateInput>
        </div>
      </FormulateForm>
    </BaseModal>
  </div>
</template>

<script>
import { CHECKLIST_COLUMN_TITLES } from '@/utils/helpers'

import ChecklistKanban from '@/components/properties/ChecklistKanban.vue'

import {
  getPropertyChecklistV2,
  getProjectChecklistV2,
  getChecklistTemplates,
  createChecklist,
  loadPropertyChecklistTemplate,
  loadProjectChecklistTemplate,
  sendPropertyChecklistNotification,
  sendProjectChecklistNotification
} from '@/services/checklists'
import { errorModal, successModal } from '@/modalMessages'
import { mapGetters } from 'vuex'

export default {
  name: 'EntityChecklist',
  components: {
    ChecklistKanban
  },
  constants: {
    CHECKLIST_COLUMN_TITLES
  },
  data () {
    return {
      categories: {},
      checklistTemplates: [],
      includeNvt: false,
      expandedTodos: true,
      showOnlyAgent: false,
      showOnlySalesSupport: false
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertyById', 'getProjectById']),

    propertyId () {
      return this.$route.meta.entity_type === 'property' ? this.$route.params.id : null
    },
    projectId () {
      return this.$route.meta.entity_type === 'project' ? this.$route.params.id : null
    },
    entity () {
      const entity = this.propertyId
        ? this.getPropertyById(this.propertyId)
        : this.getProjectById(this.projectId)
      return {
        ...entity,
        categories: this.categories
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    showLoadChecklistModal () {
      this.$refs.loadChecklists.show()
    },
    hideLoadChecklistModal () {
      this.$refs.loadChecklists.hide()
    },
    async init () {
      try {
        const response = await Promise.all([
          this.fetchChecklistTemplates(),
          this.loadChecklists()
        ])
        return response
      } catch (error) {
        // 404 is expected since the checklist doesn't exist by default and a 404 is returned.
        if (error.response.status === 404) return await this.createNewChecklist()
      }
    },
    async createNewChecklist () {
      try {
        const payload = this.propertyId
          ? { property: this.propertyId }
          : { project: this.projectId }
        const response = await createChecklist(payload)
        this.categories = response.data
        return response
      } catch (error) {
        errorModal('Er ging iets mis bij het aanmaken van een checklist.')
      }
    },
    async fetchChecklistTemplates () {
      const payload = this.propertyId
        ? { property_id: this.propertyId }
        : { project_id: this.projectId }
      const response = await getChecklistTemplates(payload)
      this.checklistTemplates = response.data?.results?.map(({ name, id }) => {
        return { label: name, value: id }
      })
      return response
    },
    async loadChecklists () {
      const response = this.propertyId
        ? await getPropertyChecklistV2(this.propertyId)
        : await getProjectChecklistV2(this.projectId)
      this.categories = response.data
      return response
    },
    async loadTemplate (data) {
      try {
        const response = this.propertyId
          ? await loadPropertyChecklistTemplate(this.propertyId, data)
          : await loadProjectChecklistTemplate(this.projectId, data)
        await this.loadChecklists()
        this.hideLoadChecklistModal()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'loadChecklists')
      }
    },
    async sendChecklistNotification () {
      const response = await (this.propertyId
        ? sendPropertyChecklistNotification
        : sendProjectChecklistNotification
      )(this.propertyId || this.projectId)
      successModal('Een e-mail werd verstuurd naar de makelaar en sales support(s).', false)
      return response
    }
  }
}
</script>
